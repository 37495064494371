<!--
 * File Name: ForgotPassword.vue
 * Description: Forgot Password Page
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
-->

<template>
  <full-page>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        <vx-card>
          <div
            slot="no-body"
            class="full-page-bg-color"
          >
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col hidden lg:block lg:w-1/2">
                <img
                  src="/rj-logo.svg"
                  alt="login"
                  class="mx-auto"
                  style="width: 400px"
                >
              </div>
              <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                <div class="p-8 login-tabs-container">
                  <form
                    @submit.prevent="submit"
                  >
                    <div class="vx-card__title mb-8">
                      <h4 class="mb-4">
                        Reset your password
                      </h4>
                      <p v-if="!passwordReset">
                        Please enter a new password.
                      </p>
                    </div>

                    <vs-input
                      v-if="!passwordReset"
                      v-model="password1"
                      v-validate="'required'"
                      type="password"
                      name="password"
                      placeholder="New Password"
                      class="w-full mb-1"
                    />
                    <span
                      v-show="errors.has('password')"
                      class="text-danger text-sm"
                    >
                      Please enter a new password.
                    </span>

                    <vs-input
                      v-if="!passwordReset"
                      v-model="password2"
                      v-validate="'required'"
                      type="password"
                      name="confirmNewPassword"
                      placeholder="Confirm New Password"
                      class="w-full mb-1"
                    />
                    <span
                      v-show="errors.has('confirmNewPassword')"
                      class="text-danger text-sm"
                    >
                      Please confirm your new password.
                    </span>
                    <span
                      v-show="error"
                      class="text-danger text-sm"
                    >
                      {{ error }}
                    </span>

                    <div class="flex justify-between inline-block mt-6">
                      <!-- class="px-4 w-full md:w-auto" -->
                      <vs-button
                        type="border"
                        to="/login"
                      >
                        Back To Login
                      </vs-button>
                      <!-- class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0" -->
                      <vs-button
                        v-if="!passwordReset"
                        button="submit"
                      >
                        Save
                      </vs-button>
                    </div>
                    <p
                      v-if="passwordReset"
                      class="text-green-500 py-3"
                    >
                      Your new password has been saved.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </full-page>
</template>

<script>
import FullPage from '@/layouts/full-page/FullPage.vue';
import Guest from '@/http/middleware/RedirectIfAuthenticated';

export default {
  name: 'RjForgotPassword',

  components: { FullPage },

  middleware: [Guest],

  data() {
    return {
      error: '',
      password1: '',
      password2: '',
      passwordReset: false,
    };
  },

  methods: {
    async submit() {
      this.error = '';
      const { token } = this.$route.params;
      if (!token) {
        this.error = 'Invalid link. Please click the button below to send a new reset link.';
        return false;
      }
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      if (this.password1 !== this.password2) {
        this.error = 'Password must match.';
        return false;
      }

      if (this.password1.length < 8) {
        this.error = 'Password must be at least 8 characters.';
        return false;
      }

      const result = await this.$store.dispatch('auth/resetPasswordAccept', { token, password: this.password1 });
      if (result) {
        this.passwordReset = true;
      } else {
        this.error = 'An error occurred. Please try again or contact support.';
      }
      return true;
    },
  },
};
</script>
