<template>
  <full-page>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        <vx-card>
          <div
            slot="no-body"
            class="full-page-bg-color"
          >
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col hidden lg:block lg:w-1/2">
                <img
                  src="/rj-logo.svg"
                  alt="login"
                  class="mx-auto w-2/3"
                  style="width: 400px"
                >
              </div>
              <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                <div class="p-8 login-tabs-container">
                  <form @submit.prevent="authenticate">
                    <div class="vx-card__title mb-4">
                      <h4 class="mb-4">
                        Login
                      </h4>
                      <p>
                        Welcome back! Don't have an account?
                        <a
                          href="https://www.revenuejump.com/get-started"
                          style="color: rgba(var(--vs-primary), 1);"
                        >
                          Get Started
                        </a>
                      </p>
                      <p v-if="error">
                        <span class="text-red-500">Incorrect email or password.</span>
                      </p>
                    </div>
                    <div>
                      <vs-input
                        v-model="credentials.email"
                        name="email"
                        icon-no-border
                        icon="icon icon-user"
                        icon-pack="feather"
                        label-placeholder="Email"
                        class="w-full"
                      />

                      <vs-input
                        v-model="credentials.password"
                        type="password"
                        name="password"
                        icon-no-border
                        icon="icon icon-lock"
                        icon-pack="feather"
                        label-placeholder="Password"
                        class="w-full mt-6"
                      />

                      <div class="flex justify-between inline-block mt-6">
                        <router-link
                          to="/forgot-password"
                          style="color: rgba(var(--vs-primary), 1);"
                          class="mt-auto mb-auto"
                        >
                          Reset Password
                        </router-link>
                        <vs-button
                          class="align-middle"
                          button="submit"
                        >
                          Login
                        </vs-button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </full-page>
</template>

<script>
import FullPage from '@/layouts/full-page/FullPage.vue';
import Guest from '@/http/middleware/RedirectIfAuthenticated';

export default {

  components: { FullPage },

  middleware: [Guest],

  data() {
    return {
      credentials: {
        email: '',
        password: '',
      },
      error: false,
      checkbox_remember_me: false,
    };
  },

  created() {
    this.$store.commit('partners/RESET_STATE');
    this.$store.commit('companies/RESET_STATE');
  },

  methods: {
    async authenticate() {
      this.error = false;

      try {
        const redirectTo = await this.$store.dispatch('auth/login', this.credentials);
        if (redirectTo) {
          window.location.href = redirectTo;
        } else {
          this.$router.replace({ name: 'home-view' });
        }
      } catch (err) {
        this.error = true;
        this.credentials.password = '';
      }
    },
  },
};
</script>
