<!--
 * File Name: ForgotPassword.vue
 * Description: Forgot Password Page
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
-->

<template>
  <full-page>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        <vx-card>
          <div
            slot="no-body"
            class="full-page-bg-color"
          >
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col hidden lg:block lg:w-1/2">
                <img
                  src="/rj-logo.svg"
                  alt="login"
                  class="mx-auto"
                  style="width: 400px"
                >
              </div>
              <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                <div class="p-8 login-tabs-container">
                  <form
                    @submit.prevent="submit"
                  >
                    <div class="vx-card__title mb-8">
                      <h4 class="mb-4">
                        Recover your password
                      </h4>
                      <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
                    </div>

                    <vs-input
                      v-model="email"
                      v-validate="'required'"
                      type="email"
                      name="email"
                      label-placeholder="Email"
                      class="w-full"
                    />
                    <span
                      v-show="errors.has('email')"
                      class="text-danger text-sm"
                    >
                      Please enter your email address.
                    </span>

                    <div class="flex justify-between inline-block mt-6">
                      <!-- class="px-4 w-full md:w-auto" -->
                      <vs-button
                        type="border"
                        to="/login"
                      >
                        Back To Login
                      </vs-button>
                      <!-- class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0" -->
                      <vs-button button="submit">
                        Reset Password
                      </vs-button>
                    </div>
                    <p
                      v-if="resetPasswordEmailSent"
                      class="text-green-500 py-2"
                    >
                      Check your email for instructions
                      to reset your password.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </full-page>
</template>

<script>
import FullPage from '@/layouts/full-page/FullPage.vue';
import Guest from '@/http/middleware/RedirectIfAuthenticated';

export default {
  name: 'RjForgotPassword',

  components: { FullPage },

  middleware: [Guest],

  data() {
    return {
      email: '',
      resetPasswordEmailSent: false,
    };
  },

  methods: {
    async submit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }
      this.$store.dispatch('auth/resetPassword', this.email.trim());
      this.resetPasswordEmailSent = true;
      return true;
    },
  },
};
</script>
